import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import HTTPService from 'services/HTTPServices';
import useOnClickOutside from "shared/hooks/useOnClickOutside";
import { KEY_LANGUAGE } from 'config/localStorageKey';

import EnglandFlag from 'assets/img/flags/flag_en_icon.png';
import VietnameseFlag from 'assets/img/flags/flag_vi_icon.png';
import IconDropdown from 'assets/img/flags/icon_check.png';
import IconLanguage from 'assets/img/flags/ic_langu.png';
import { useStyles } from "./styles";

const ApiDomain = process.env.REACT_APP_API_DOMAIN;

const listLanguage = [
	{
		id: 1,
		language: "English",
		flag: EnglandFlag,
		iconLang: IconLanguage,
		code: "en"
	},
	{
		id: 2,
		language: "Vietnamese",
		flag: VietnameseFlag,
		iconLang: IconLanguage,
		code: "vi"
	}
];

export const updateLanguage = () => {
	const language = localStorage.getItem(KEY_LANGUAGE);

	return HTTPService.sendRequestWithToken('POST', ApiDomain + '/api/v1/profile/change-language', undefined, {
		language: language
	})
};

const ChangeLanguage = () => {
	const classes = useStyles();
	const { i18n } = useTranslation();
	const [openDropdownLanguage, setOpenDropdownLanguage] = useState(false);
	const [currentLanguage, setCurrentLanguage] = useState(listLanguage[0]);
	const refDropdownLanguage = useRef();

	useEffect(() => {
		const i18nextLng = localStorage.getItem(KEY_LANGUAGE);
		if (i18nextLng) {
			const language = listLanguage.find(lang => lang.code === i18nextLng);
			if (language) {
				return setCurrentLanguage(language);
			}
		}
		return setCurrentLanguage(listLanguage[0]);
	}, []);

	useOnClickOutside(refDropdownLanguage, () => setOpenDropdownLanguage(false));

	const selectLanguage = language => {
		setCurrentLanguage(language);
		setOpenDropdownLanguage(false);
		i18n.changeLanguage(language.code);
		localStorage.setItem(KEY_LANGUAGE, language.code);
	};

	const renderListLanguage = () => {
		if (openDropdownLanguage) {
			const i18nextLng = localStorage.getItem(KEY_LANGUAGE);
			return (
				<div className={classes.listLanguageDropdown}>
					{listLanguage.map(item => {
						let classLaguage = classes.itemLanguageDropdown
						if (item.code === i18nextLng) {
							classLaguage = classes.itemLanguageDropdown_selected
						}
						
						return (
						<div
							key={item.id}
							className={classLaguage}
							onClick={() => selectLanguage(item)}>
							<img
								src={item.flag}
								alt="flag"
								className={classes.dropDownLanguage__countryFlag} />
							<span className={classes.itemLanguageDropdown_language}>{item.language}</span>
						</div>
					)}
					)}
				</div>
			);
		}
		return "";
	};

	const onOpenDropDownLanguage = () => {
		setOpenDropdownLanguage(!openDropdownLanguage);
	};
	
	return <div className={classes.dropDownLanguage} onClick={onOpenDropDownLanguage} ref={refDropdownLanguage}>
		<img
			src={currentLanguage.iconLang}
			alt="flag"
			className={classes.language__countryFlag} />
		<div className={classes.dropDownLanguage__countryText}>
			{currentLanguage.language}
		</div>
		<img alt="choose" src={IconDropdown} className={classes.dropDownLanguage__dropdownIcon} /> {renderListLanguage()}
	</div>
};

export default ChangeLanguage
