import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'context/AuthContext'
import { Background, RouterContext } from 'context/RouterContext';
import { get } from 'lodash';
import TableTopThreeView from './../Home/TableTopThree'
import { useStyles } from './styles';
import Api from 'services/Api';
import TimerView from './../TimerView';

const Home = (props) => {
	const classes = useStyles()
	const Router = useContext(RouterContext);
	const authContent = useContext(AuthContext);
	const [mapDataDefault, setMapDataDefault] = useState({
		drawTime: {},
		prizeList: [],
		singlePrizes: []
	})

	useEffect(() => {
		Api.requestHomePage().then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (isSuccess) {
				const data = get(res, 'data.data', {});
				const hData = authContent.mapDataHomePage(data)
				authContent.resultHomeData = hData;
				setMapDataDefault(hData)
			}
		});
	}, [authContent]);

	useEffect(()=> {
		Router.setBackground(Background.home);
	}, [Router])
	
	//============================//
	//
	//============================//
	
	return(
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .3s' }}>
			<div className={classes.content}>
				<div className={classes.timer__container}>
					<TimerView  data={mapDataDefault.timeNextDraw} />
				</div>	
				<TableTopThreeView data={mapDataDefault.singlePrizes} period={mapDataDefault.period}/>
			</div>
			<br />
		</div>
	)
};

export default Home
