import React, { useState, useEffect, useContext} from "react";
import { Background, RouterContext } from 'context/RouterContext';
import { get, map } from 'lodash';
import { useTranslation } from 'react-i18next';

import Api from 'services/Api';
import DialogDetail from './DialogDetail'

import { useStyles } from './styles';

const Results = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const Router = useContext(RouterContext);
	const [dataMap, setDataMap] = useState({})
	
	useEffect(()=> {
		Router.setBackground(Background.other);
	}, [Router])

	useEffect(() => {
		Api.requestPageResult().then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (!isSuccess) {
				// can not get data
				//render maintainer page
			} else {
				const data = get(res, 'data.data', {});
				setDataMap(data);
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t])

	return (
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .1s' }}>
			<div className={classes.container}>
				{map(dataMap, (item, index) => {
					return <DialogDetail key={index} index={index} data={item} />
				})}
			</div>
		</div>
	)
};



export default Results