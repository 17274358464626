import React, {useContext, useEffect} from "react";
import { useTranslation } from 'react-i18next';
import { Background, RouterContext } from 'context/RouterContext';
import { useStyles } from './styles';

const AboutUs = () => {
	const classes = useStyles()
	const Router = useContext(RouterContext);
	const { t } = useTranslation();
	useEffect(() => {
		Router.setBackground(Background.home);
	}, [Router])

	return (
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .1s' }}>
			<div className={classes.about_title}>
				<span>
					{t('AboutUs')}
				</span>
			</div>
			<div className={classes.content}>
				<span>
					<div className={classes.content_group}>
						<pre className={classes.content_center}>
							{t('AboutUsContent')}
						</pre>
					</div>
				</span>
			</div>
			<br />
		</div>
	)
};

export default AboutUs
