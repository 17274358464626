import { makeStyles } from "@material-ui/core/styles";
import { font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: '100%',
  },
  multi_prize_container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    '& span': {
      padding: '0 0 0 15px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 0 0 10px',
        fontSize: font.nomalSize__mobile,
      },
    },
    '& :nth-child(1)': {
      color: '#fff'  
    },
    '& :nth-child(2)': {
      color: '#1ab50d'  
    },

    '& :nth-child(3)': {
      color: '#ffbb02'
    },
  },
  menus: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-around',
    flexDirection: 'column',
    flexFlow: 'column',
    alignItems: 'center',
    width: '200px',
    height: '490px', 
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: '0',
      flexDirection: 'row',
      height: '40px', 
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '0',
      width: '100%',
      height: '28px', 
    }
  },


  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px 10px 10px'
    },
    [theme.breakpoints.down('xs')]: {
      // padding: '10px'
      // width: 'calc(100% -120px)',
    }
  },

  timer__container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '80px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginBottom: '20px',
    }
  }

}));
