import { makeStyles } from "@material-ui/core/styles";
import { font } from "config/styles";
import bg_title_top from 'assets/img/home/tittle.png';

export const useStyles = makeStyles((theme) => ({
  winners: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    borderRadius: '5px',
    justifyContent:'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // backgroundColor: '#2e041c',
    backgroundColor: 'rgba(167, 204, 206, 0.6)',
    [theme.breakpoints.down('sm')]: {
      // maxWidth: '1560px',
      // width: '100%',
    },
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign:'center',
    
    width: '300px',
    height: '50px',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundImage: `url(${bg_title_top})`,
    
    [theme.breakpoints.down('sm')]: {
      width: '250px',
      height: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '180px',
      height: '40px',
    },
    '& span': {
      // display: 'flex',
      textTransform: 'uppercase',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      fontFamily: font.boldFamily,
      marginRight: '10%',
      height: 'auto',
      color: 'white',
      display: 'inline-block',
      verticalAlign:'middle',
      fontSize: '18px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.4vw',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.6vw',
      }
    }
  },

  winner_period: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    verticalAlign:'center',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      // justifyContent: 'center',
      // width: '100%'
    },
    '& span': {
      // display: 'flex',
      textTransform: 'uppercase',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      fontFamily: font.boldFamily,
      marginRight: '10px',
      height: 'auto',
      display: 'inline-block',
      verticalAlign:'middle',
      fontSize: '18px',
      color: 'black',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.4vw',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.6vw',
      }
    }
  },

  title_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    verticalAlign:'center',
    
    width: '100%',
    // height: '50px',
    // backgroundPosition: '50% 50%',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: '100% 100%',
    // backgroundImage: `url(${bg_title_top})`,
  },

  winners__table__wrapper: {
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: "auto 100%",
    // backgroundColor: '#fff',
    animation: 'fadeIn ease-in .5s',
    transition: 'all .5s ease-in-out',
    width: '100%',
    display: 'flex',
    alignItems:'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,

    },
  },

  winners__table: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1',
    zIndex: 2
    // backgroundColor: '#2e041c',
    
  },
  image_bg: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    zIndex: 0,
  },

  image_title: {
    // position: 'absolute',
    height: '60%',
    width: 'auto',
    marginRight: '10px'
    // zIndex: 0,
  },
  contanner__image: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '80px',
  },

  image_ball: {
    position: 'absolute',
    zIndex: 1,
    top: '-5%',
    left: '85%',
    height: '80px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
    [theme.breakpoints.down('xs')]: {
      margin:'2px 2px 0px 2px',
      height: '50px',
    }
  },
}));
