
import HTTPService from './HTTPServices';
const ApiDomain = process.env.REACT_APP_API_DOMAIN;

const Api = {};
Api.requestHomePage = function () {
    return HTTPService.sendPostReqeust(ApiDomain + '/result')
};

Api.requestPageResult = function () {
    return HTTPService.sendPostReqeust(ApiDomain + '/result_paging')
}



Api.requestMeta = function (endpoint = '') {
    return HTTPService.sendPostReqeust(ApiDomain + '/metadata', undefined, {endpoint})
}

export default Api;