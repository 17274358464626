import { makeStyles } from "@material-ui/core/styles";
import { font, color } from "../../config/styles";

export const useStyles = makeStyles((theme) => ({
	dropDownLanguage: {
		position: 'relative',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		minWidth: '210px',
		height: '40px',
		maxHeight: '100%',
		backgroundColor: 'transparent',
		borderRadius: '5px',
		padding: '10px',
		cursor: 'pointer',
		border: `1px solid #000`,
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			minWidth: '160px',
			height: '30px',
			marginLeft: '10px',
			padding: '0 5px 0 5px',
			maxWidth: '160px',
			maxHeight: '40px',
		},
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			minWidth: '130px',
			height: '25px',
			marginLeft: '0px',
			padding: '0 5px 0 5px',
			maxWidth: '130px',
			maxHeight: '40px',
		},
	},

	language__countryFlag: {
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			height: '43%',
		}
	},
	
	dropDownLanguage__countryFlag: {
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			height: '70%',
		}
	},

	dropDownLanguage__countryText: {
		padding: '0 10px',
		color: color.black,
		fontSize: font.nomalSize,
		[theme.breakpoints.down('sm')]: {
			padding: '0 1vw',
			fontSize: '14px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '0 1vw',
			fontSize: '12px',
		},
	},

	listLanguageDropdown: {
		position: 'absolute',
		top: '110%',
		width: '100%',
		left: 0,
		minWidth: '210px',
		backgroundColor: 'rgba(0, 0, 0, 0.4);',
		borderRadius: '5px',
		zIndex: '1101',
		// border: `1px solid #000`,
		[theme.breakpoints.down('sm')]: {
			minWidth: '160px',
			maxWidth: '160px',
		},
		[theme.breakpoints.down('xs')]: {
			minWidth: '130px',
			maxWidth: '130px',

		},
	},

	itemLanguageDropdown: {
		display: 'inline-flex',
		alignItems: 'center',
		height: '45px',
		padding: '10px 10px',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			minWidth: '160',
			height: '30px',
			padding: '5px',
		},
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.6);',
			// borderRadius: '5px',
		}
	},

	itemLanguageDropdown_selected: {
		display: 'inline-flex',
		alignItems: 'center',
		height: '45px',
		padding: '10px 10px',
		width: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.6);',
		[theme.breakpoints.down('sm')]: {
			minWidth: '160',
			height: '30px',
			padding: '5px',
		}
	},

	itemLanguageDropdown_language: {
		marginLeft: '20px',
		color: color.white,
		[theme.breakpoints.down('sm')]: {
			// marginLeft: '6.25vw',
			marginLeft: '10px',
		},
		[theme.breakpoints.down('xs')]: {
			// marginLeft: '6.25vw',
			marginLeft: '5px',
		},
	},

	dropDownLanguage__dropdownIcon: {

		[theme.breakpoints.down('sm')]: {
			// width: '3.125vw',
			// height: '3.125vw',
		},
	}
}));
