import { Container } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";


export const color = {
  menuBackground: "#120D2B",
  mainBackground: "#dbeff0",
  tableBackground: "#11111d",
  tableHeader: "#29184e",
  inputBorder: "#2d2884",
  inputBackground: "#211638",
  drawerMenuBackground: "#19123c",
  drawerMenuActiveBackground: "#130d2f",
  dialogBackground: "#222e53",
  white: "#FFF",
  primary: "#5cddfd",
  black: '#000',
  red: "#d52913",
};

export const font = {
  smallSize: '10px',
  smallSize__mobile: "1.55vw",
  nomalSize: "16.5px",
  nomalSize__mobile: "2vw",
  nomallerSize: "12.5px",
  nomallerSize__mobile: "1.8vw",
  
  largeSize: "26.0px",
  largeSize__mobile: "3.3vw",
  bigSize: '30px',
  bigSize__mobile: '4vw',
  boldFamily: "SegoeBold",
};

export const layout = {
  headerHeight: "75px",
  headerHeight__mobile: "13.6vw",
  footerHeight: "90px",
  footerHeight__mobile: "80px",
  headerHeighBg: `calc(100vw / 1920 * 300)`,
  headerHeighBg__mobile: `calc(100vw / 1920 * 500)`,
  headerHeighHomeBg: `calc(100vw / 1920 * 300)`,
  headerHeighHomeBg__mobile: `calc(100vw / 1920 * 400)`,
  headerHeighHomeBg__xs__mobile: `calc(100vw / 1920 * 780)`,
};


export const StyledContainer = withStyles(theme => ({
  root: {
    maxWidth: '1180px',
    margin: 'auto',
    padding: '0 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      margin: '0px',
    },
  },
}))(Container);

export const StyledContainerFooter = withStyles(theme => ({
  root: {
    maxWidth: '1180px',
    margin: 'auto',
    padding: '0 20px',
    // backgroundImage: 'radial-gradient(circle, #cbd4d4 50%, #ffffff 100%)',
    minHeight: '120px', 
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      margin: '0px',
    },
  },
}))(Container);
