import { makeStyles, withStyles } from "@material-ui/core/styles";
import { color, font } from "config/styles";
import PaginationItem from '@material-ui/lab/PaginationItem';

export  const PaginationItemCustom = withStyles({
	// @global is handled by jss-plugin-global.
	'root': {
	  // You should target [class*="MuiButton-root"] instead if you nest themes.
	  "&.Mui-selected": {
		border: `1px solid #075eab`,
		color: 'black',
		backgroundColor: "white"
	  }
	},
  })(PaginationItem);

export const useStyles = makeStyles((theme) => ({
	container_page: {
		[theme.breakpoints.down('sm')]: {
			// backgroundColor: color.mainBackground
		}
	},
	container: {
		position: 'relative',
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'center',
		margin: '0 10px 0 10px',
		// backgroundColor: 'white'
	},

	result_container_header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		padding: '10px 0 10px 0',
		[theme.breakpoints.down('sm')]: {
			padding: '5px 10px 5px 10px',
		}
	},
	timeLiveDraw: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		width: '100%',
		height: 'auto',
		bottom: 'calc(100% + 20px)',
		left: '0',
		fontSize: font.largeSize,
		textTransform: 'uppercase',
		[theme.breakpoints.down('sm')]: {
			bottom: 'calc(100% + 10px)',
			justifyContent: 'center',
			alignItems: 'flex-start',
		}
	},
	liveScoreSmall: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: font.boldFamily,
		fontSize: font.largeSize,
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.8vw',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: font.largeSize__mobile,
		},
		
	},
	liveScoreSmaller: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: font.nomalSize,
		[theme.breakpoints.down('sm')]: {
			fontSize: font.nomalSize__mobile,
		}
	},
	result_title: {
		display: 'flex',
		alignItems: 'flex-end',
		'& span': {
			fontSize: font.largeSize,
			textTransform: 'uppercase',
			fontFamily: font.boldFamily,
			[theme.breakpoints.down('sm')]: {
				fontSize: font.largeSize__mobile,
			}
		},
		[theme.breakpoints.down('sm')]: {
			// marginTop: '10px'
		}
	},

	lable_image: {
		height: '14px',
		width: 'auto',
		margin: '2px 2px 0px 0px',
		[theme.breakpoints.down('xl')]: {
			height: '12px',
		}
	},

	content: {
		display: 'flex',
		margin: '2px',
		borderRadius: '6px',
		fontSize: font.nomalSize,
		background: 'linear-gradient(to right, #2963eb, #e04ad0 )',
		color: '#efeef3',
		'& span': {
			backgroundColor: color.tableBackground,
			margin: '2px',
			borderRadius: '6px',
			textAlign: 'left',
		}
	},
	result_container_content: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'space-between',
		flexDirection: 'row',
	},
	result_content: {
		display: 'flex',
		width: '100%',
		margin: '5px',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			margin: '10px'
		}
	},

	formControl: {
		minWidth: '80px',
		maxWidth: '250px',
		maxHeight: '45px',
		minHeight: '22px',
		height: '5vw',
		width: '30vw',
		backgroundColor: '#100f1d',
		border: `1px solid #075eab`,
		justifyContent: 'center',
		alignItems: 'space-between',
		borderRadius: '3px',
		color: '#000',
		padding: '0 1vw 0 1vw',
		'& div': {
			color: '#fff',
			fontSize: font.nomalSize,
			[theme.breakpoints.down('sm')]: {
				fontSize: font.nomalSize__mobile,
			}
		},

		'& :after': {
			display: 'none'
		},

		'& svg': {
			color: 'white',
			right: '0px'
		}
	},
	slideContainer: {
		display: 'flex'
	},
	search__containner: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end',
		alignItems: 'center',
		zIndex: 4,
		marginBottom: '10px'
	},
	date__picker: {
		border: `1px solid #5ab6f7`,
		borderRadius: '15px',
		height: '32px',
		width: '180px',
		padding: '5px',
		fontFamily: font.boldFamily,
		backgroundColor: 'white',
		color: 'black',
		'&:hover': {
			backgroundColor: 'rgba(90, 182, 247, 0.6);',
		}
	}
}));
