import React from 'react';
import Image from 'react-image-webp';
import { useTranslation } from 'react-i18next';
import { Hidden } from '@material-ui/core';
import { useStyles } from './styles'
import ChangeLanguage from './../../../components/ChangeLanguage';

import Logo from 'assets/img/home/logo.png';
import LogoWepb from 'assets/imgWebp/logo.webp';

const LogoCompany = ({isHomePage, data}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let className = classes.containner
  if (isHomePage) {
    className = classes.containner_home
  }

  const renderContent =()=> {
    if (isHomePage) {
      return <>
         <span><b>{t('hometext1')} {data.close_time}</b></span>
          <span><i>- {t('hometext2')} {data.third_draw_time}</i></span>
          <span><i>- {t('hometext3')} {data.second_draw_time}</i></span>
          <span><i>- {t('hometext4')} {data.first_draw_time}</i></span>
      </>
    }
    return null
  }

  return (
    <>
      <div className={className}>
        <div className={classes.content}>
          <Hidden smUp>
            <div className={classes.contanner__language}>
              <ChangeLanguage />
            </div>
          </Hidden>
          {renderContent()}
        </div>
        <Image alt='logo' src={Logo} webp={LogoWepb} className={classes.img_logo} />
      </div>
    </>
  )
};

export default LogoCompany
