import { makeStyles } from "@material-ui/core/styles";
import { font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      
    }
  },
  timer_title: {
    zIndex: 1,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    '& span': {
      padding:'5px',
      color: 'black',
      fontSize: '16px',
      fontFamily: font.boldFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.0vw',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      }
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px 0 10px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px 0 20px',
    }
  },
  
  timer__bg: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '115px',
    height: '102px',
    [theme.breakpoints.down('sm')]: {
			// width: '8vw',
      // height: '8vw',
      width: '94px',
      height: '83px',
    },
    [theme.breakpoints.down('xs')]: {
			width: '84px',
      height: '74px',
    },
    
    '& span': {
      zIndex: 1,
      fontSize: '60px',
      paddingBottom: '10px',
      // fontFamily: 'Digital',
      fontFamily: font.boldFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: '55px',
        paddingBottom: '10px'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '50px',
        paddingBottom: '5px'
      }
    },
    '& pre': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bottom: '5px',
      fontSize: '12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '8px',
        bottom: '4px',
      }
    }
  },

  timer__bg__point: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '15px',
    height: '30px',
    '& span': {
      zIndex: 1,
      fontSize: '50px',
      fontFamily: 'Digital',
      [theme.breakpoints.down('sm')]: {
        fontSize: '4.5vw',
      }
    }
  },
  content: {
    display: 'flex',
    margin: '1px',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: font.nomalSize,
    '& span': {
      margin: '1px',
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '0 10px 0 10px',
    }
  },

  date_content: {
    display: 'flex',
    margin: '1px',
    color: 'white',
    fontSize: font.nomalSize,
    fontFamily: font.boldFamily,
    '& span': {
      margin: '1px',
      textAlign: 'center',
      fontSize: font.nomalSize,
      fontFamily: font.boldFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.nomalSize__mobile,
      }
    },
  },
  content_center: {
    display: 'flex',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    fontSize: font.nomalSize,
    margin: '10px',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '0 20px 0 20px',
    }
  },

  imageItemFlag: {
    position: 'absolute',
    left: '50%',
    bottom: '50%',
    transform: 'translate(-50%, 50%)',
    height: '100%',
    // margin: '5px',
    zIndex: 0,
  },
  imageClamp: {
    position: 'absolute',
    left: '50%',
    bottom: '80%',
    transform: 'translate(-50%, 50%)',
    height: '200px',
    zIndex: 0,
		[theme.breakpoints.down('sm')]: {
			height: '160px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '20vw',
		}
  }
  
}));
