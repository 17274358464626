import { makeStyles } from "@material-ui/core/styles";
import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  numbers: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'column',
    // backgroundColor: '#2e041c',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  },

  number_title: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    // height: '50px',
    width:'100%',
    [theme.breakpoints.down('sm')]: {
      // height: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      // height: '30px',
    },
  },

  header_title: {
    '& span': {
      color: '#25272c',
      fontFamily: font.boldFamily,
      fontSize: font.largeSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.bigSize__mobile
      }
    }
  },
  imageTop: {
    height: '100%',
    width: 'auto'
  },

  number__body: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'row',
    padding: '2px 0px 10px 0px'
  },

  number__body_background: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    opacity: '0.3',
    zIndex: '0',
  },  

  number_content: {
    boxSizing: 'content-box',
    borderRadius: '5px',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAglim: 'center',
    padding: '1px',
    margin: '3px',
    width: '40px',
    height: '54px',
    fontSize: '30px',
    backgroundColor: 'rgba(0, 0, 0, 0.6);',
    fontFamily: 'UTMSwiss721BlackCondensed',
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '4px',
      fontSize: '24px',
      width: '35px',
      height: '45px',
      margin: '2px',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '4px',
      fontSize: '24px',
      width: '32px',
      height: '39px',
      margin: '1px',
    }
  },
  image_title: {
    height: 'auto',
    width: '35px',
    marginRight: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '35px',
      marginRight: '3px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '25px',
      marginRight: '2px',
    },
    [theme.breakpoints.down('500')]: {
      width: '20px',
      marginRight: '2px',
    }
  }
}));
