const vietnamese = {
  translations: {
    "AboutUs": "About Us",
    "Date": "Date",
    "FooterCopyright": "COPYRIGHT © 2010 [company_name]. ALL RIGHTS RESERVED.",
    "Home": "Home",
    //end error message
    "Results": 'Results',
    'Day': 'Day',
    'TimeRemaning': 'We are launching in:',
    'At': 'at',
    'CompanyName': 'SAIGONLOTTERY',
    'FooterText1': 'All times displayed in Indochina Time (ICT is UTC+7) unless stated otherwise\nPlease note: Pricing on SAIGONLOTTERY.com differs from that found at traditional retail lottery agencies.',
    'FooterText2': 'Deposit funds into your player account via the following methods:',
    'WinningResults': 'WINNING RESULTS',
    'NoResults': 'No Results',
    'hour': 'Hours',
    'minute': 'Minutes',
    'second': 'Seconds',
    'period': 'Period',
    'hometext1': 'Lottery Draw will start everyday at ',
    'hometext2': 'Third Place drawing starts at',
    'hometext3': 'Second Place drawing starts at',
    'hometext4': 'First Place drawing starts at',
    'AboutUsContent': 'Saigon Lottery is the website where you look for your bets. Thanks to our many games, you can enjoy for hours while you wait to win one of our many boats. Regularly updated by our specialists, this game offers an extraordinary ergonomics and visual quality. The new additions allow the reach to grow and meet the new expectations of our customers.'
  }
};

export default vietnamese;
