import React from 'react';
import TableNumber from 'components/Numbers';
import { useStyles } from './styles';
import Image from 'react-image-webp';
import './styles.css'

import calendar from 'assets/img/home/ic_calendar.png';
import calendarWepb from 'assets/imgWebp/ic_calendar.webp';


const TableTopThree = ({data = [], period = {period : '', date: ''}}) => {
  const classes = useStyles()
  let periodInfo = `Period: ${period.period}`
	if (!period) {
		period = {period : '', date: ''}
	}
  return <div className={classes.winners}>
      <div className={classes.title_container}>
        <div className={classes.winner_title}>
            <Image className={classes.image_title} alt='ball' src={calendar} webp={calendarWepb} />
            <span>
              {period.date}
            </span>
        </div>
        <div className={classes.winner_period}>
            <span>
              {periodInfo}
            </span>
        </div>
      </div>
      <div className={classes.winners__table__wrapper}>
        {
          data.map((item, index)=> {
            return <div key={index} className={classes.winners__table}>
                <TableNumber data={item} index={index} />
            </div>
          })
        }
      </div>
  </div>
};

export default TableTopThree;
