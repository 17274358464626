import React, {useState, useRef, useEffect} from "react";
import Image from 'react-image-webp';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

import label from 'assets/img/lives/box_clock.png';
import labelWepb from 'assets/imgWebp/box_clock.webp';

import pont from 'assets/img/lives/point_time.png';
import pontWepb from 'assets/imgWebp/point_time.webp';

function useInterval(callback, delay) {
	const savedCallback = useRef();
	
	const tick = ()=> {
		if (savedCallback.current) {
			savedCallback.current();
		}
	}
	// Remember the latest callback.
	useEffect(() => {
	  savedCallback.current = callback;
	}, [callback]);
	
	// Set up the interval.
	useEffect(() => {
	  if (delay !== null) {
		let id = setInterval(tick, delay);
		return () => clearInterval(id);
	  }
	}, [delay]);
}
  
const TimerView = ({data = {}}) => {
	const classes = useStyles()
	const {t} = useTranslation();
	const [dataTimer, setDataTimer] = useState({})
	const [timer, setTimer] = useState({
		seconds: '00',
		hours: '00',
		minutes: '00',
		canReloadPage: false,
	})

	const handleTimer = ()=> {
		let timeUntilNextResultSeconds =  dataTimer.timeUntilNextResultSeconds
		if (timeUntilNextResultSeconds && timeUntilNextResultSeconds > 0) {
				timeUntilNextResultSeconds -= 1
				let hours = parseInt(timeUntilNextResultSeconds / (60 * 60 ), 10)
				timeUntilNextResultSeconds -= (hours * 60 * 60)
				let minutes = parseInt(timeUntilNextResultSeconds / 60, 10)
				timeUntilNextResultSeconds -= minutes * 60
				let seconds = parseInt(timeUntilNextResultSeconds , 10)
		
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = seconds < 10 ? "0" + seconds : seconds;
				hours = hours < 10 ? "0" + hours : hours;
		
				setDataTimer({
					...data,
					timeUntilNextResultSeconds: dataTimer.timeUntilNextResultSeconds - 1
				})
				
				setTimer({
					seconds,
					hours,
					minutes,
					canReloadPage: true,
				})
			} else {
				if (timer.canReloadPage) {
					window.location.reload();
				}
				
				setTimer({
					seconds: '00',
					hours: '00',
					minutes: '00',
					canReloadPage: false,
				})
			}
	}
	
  	useInterval(handleTimer, 1000);
  
  	useEffect(() => {
		setDataTimer(data);
	}, [data])

  return (
	  <div className={classes.container_page} style={{ animation: 'fadeIn ease-in .1s' }}>
      		<div className={classes.timer_title}>
        		<span>
          			{t('TimeRemaning')}</span>
      		</div>
		  	<div className={classes.content}>
			  	<div className={classes.timer__bg}>
				  <Image alt='bet winner' src={label} webp={labelWepb} className={classes.imageItemFlag} />
				  <span>{timer.hours}</span>
				  <pre>{t('hour')}</pre>
				</div>
				<div className={classes.timer__bg__point}>
					<Image alt='bet with pool' src={pont} webp={pontWepb} className={classes.imageItemFlag} />
				</div>
			  	<div className={classes.timer__bg}>
			  		<Image alt='bet with pool' src={label} webp={labelWepb} className={classes.imageItemFlag} />
					<span>{timer.minutes}</span>
					<pre>{t('minute')}</pre>
				</div>
				<div className={classes.timer__bg__point}>
					<Image alt='bet winner' src={pont} webp={pontWepb} className={classes.imageItemFlag} />
				</div>
			  	<div className={classes.timer__bg}>
			  		<Image alt='bet winner' src={label} webp={labelWepb} className={classes.imageItemFlag} />
					<span>{timer.seconds}</span>
					<pre>{t('second')}</pre>
				</div>
		  </div>
		  <div className={classes.date_content}>
		  	<span>{dataTimer.date}</span>
		  </div>
    </div>
  )
};

export default TimerView
