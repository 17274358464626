import { makeStyles } from "@material-ui/core/styles";

import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    display: 'flex',
    width: '23%',
    fontSize: '30px',
    justifyContent: 'flex-start',
    boxShadow: '1px 1px 10px 5px rgba(0,0,0,0.2)',
    alignItems: 'flex-start',
    flexDirection:'column',
    backgroundColor: 'white',
    borderRadius: '5px',
    zIndex: '2',
    margin: '0 10px 10px 10px',
    [theme.breakpoints.down('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '45%',
    },
    [theme.breakpoints.down('320')]: {
      width: '90%',
    }
  },


  winners__table__wrapper: props => ( {
    display: 'flex',
    width: '100%',
    
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomallerSize__mobile,
    
    },
  }),

  winners__table: {
    display: 'inline-block',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '5px',
    backgroundColor: '#fff',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
    },
    '& pre': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      fontFamily: font.boldFamily,
      margin: '0px',
      color: '#25272c',
      fontSize: '18px',
      [theme.breakpoints.down('sm')]: {
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.4vw',
      }
    }
    
  },

  title__group: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0px',
      color: '#25272c',
      fontSize: font.nomallerSize,
      [theme.breakpoints.down('sm')]: {
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.2vw',
      }
    }
  },

  group__result: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '0px',
      color: '#25272c',
      fontSize: font.nomallerSize,
      [theme.breakpoints.down('sm')]: {
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.nomallerSize__mobile,
      }
    }
  },

  title_name: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',  
    fontFamily: font.boldFamily,
    backgroundColor: '#efbb70',
    padding: '5px',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '0px',
      color: 'white',
      fontSize: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.4vw',
      }
    }
  },

  group__result_m: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    '& span': {
      borderRadius: '3px',
      color: '#000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAglim: 'center',

      padding: '1px',
      margin: '2px',
      width: '25px',
      height: '30px',
      fontSize: '20px',
      backgroundColor: '#e2eaed',
      fontFamily: font.boldFamily,
      zIndex: '1',
      [theme.breakpoints.down('sm')]: {
        borderRadius: '2px',
        fontSize: '18px',
        width: '20px',
        height: '25px',
        margin: '2px',
      },
      [theme.breakpoints.down('xs')]: {
        borderRadius: '2px',
        fontSize: '12px',
        width: '18px',
        height: '22px',
        margin: '2px',
      }
    }
  },

  rowMultiItenm: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& span': {
      display: 'flex',
      width: '80px',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0px',
      color: '#25272c',
      fontSize: font.nomallerSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.nomallerSize__mobile,
        width: '50%',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.nomallerSize__mobile,
      }
    }
  },

  image_title: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
    }
  },
  bold__content: {
    fontFamily: font.boldFamily,
  }
}));
