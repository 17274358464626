import { createContext } from 'react';
import { get, map } from 'lodash';
import { stringToArray } from './../utils'

const mapDataHomeResult = function (data) {
	const periodNext = get(data, 'running_period', {})
	const periodInfo = get(data, 'latest_result_period', {})
	const results = get(data, 'result', {})
	const singlePrizeListData = get(results, 'singlePrizeList', [])
	const singlePrizes = map(singlePrizeListData, (item, index) => {
		return {
			...item,
			index: index + 1,
			numbers: stringToArray(item.result)
		}
	})
	
	return {
		period: periodInfo,
		timeNextDraw: {
			...periodNext,
			timeUntilNextResultSeconds: periodNext.time_until_next_result_seconds
		},
		singlePrizes: singlePrizes.slice(0, 3),
	}
}

export const AuthContext = createContext({
	homeFunc: null,
	dataList: {},
	resultHomeData: {
		period: {},
		timeNextDraw: {},
		singlePrizes: [],
	},
	mapDataHomePage: mapDataHomeResult,
});
