import React, { useContext } from 'react';
import { Hidden } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './../../components/ChangeLanguage';
import { AuthContext } from 'context/AuthContext'
import { useStyles } from "./styles"

const Menu = () => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const { t } = useTranslation();
	const handleClick = () => {
		if (authContext.homeFunc) {
			authContext.homeFunc();
		}
	}

	const handleResultClickClick = () => {
		if (authContext.resultFunc) {
			authContext.resultFunc();
		}
	}

	return <div className={classes.menu__list}>
		<Hidden xsDown>
			<div className={classes.menu__item_language}>
				<ChangeLanguage />
			</div>
		</Hidden>
		<div className={classes.menu__routers}>
			<div className={classes.menu__item}>
				<NavLink exact onClick={handleClick} className={classes.menu__item_link} activeClassName={classes.menu__item_selected} to={'/'}>{t('Home')}
				</NavLink>
			</div>

			<div className={classes.menu__item}>
				<NavLink exact onClick={handleResultClickClick} className={classes.menu__item_link} activeClassName={classes.menu__item_selected} to={'/result'}>{t('Results')}
				</NavLink>
			</div>

			<div className={classes.menu__item}>
				<NavLink exact className={classes.menu__item_link} activeClassName={classes.menu__item_selected} to={'/about-us'}>{t('AboutUs')}
				</NavLink>
			</div>

		</div>
	</div>
};

export default Menu
