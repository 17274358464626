import React from 'react';
import Image from 'react-image-webp';
import { useStyles } from './styles';

import cupTop1 from 'assets/img/home/cup_top_1.png';
import cupTop1Wepb from 'assets/imgWebp/cup_top_1.webp';

import cupTop2 from 'assets/img/home/cup_top_2.png';
import cupTop2Wepb from 'assets/imgWebp/cup_top_2.webp';

import cupTop3 from 'assets/img/home/cup_top_3.png';
import cupTop3Wepb from 'assets/imgWebp/cup_top_3.webp';


const imageCups = [
	{
		url: cupTop1,
		urlWebp: cupTop1Wepb
	},
	{
		url: cupTop2,
		urlWebp: cupTop2Wepb
	},
	{
		url: cupTop3,
		urlWebp: cupTop3Wepb
	}
]

const Numbers = ({ data = {}, index = 0 }) => {
	const classes = useStyles();
	const renderHeader = () => {
		const { id, name } = data;
		const classNameRainbow = `header_title_${id}`
		return (
			<div className={classes.header_title}> <span className={classNameRainbow}> {name}</span> </div>
		)
	};

	const renderBody = () => {
		const { numbers } = data;
		if (numbers) {
			return numbers.map((num, i) => {
				return <div key={i} className={classes.number_content}>
					{num}
					</div>
			})
		}
		return null;
	};

	const imageTop = imageCups[index] || {}

	return <div className={classes.numbers}>
		<div className={classes.number_title}>
			<Image className={classes.image_title} alt='ball' src={imageTop.url} webp={imageTop.urlWebp} />
			{renderHeader()}
		</div>
		<div className={classes.number__body}>
			<div className={classes.number__body_background}/>
			{renderBody()}
		</div>
	</div>
};

export default Numbers;
