import React, { useState, useEffect } from 'react';
import { map, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {stringToArray} from './../../../utils'
import { useStyles } from './styles';

const colorBackgroundLevel = [
	{
		backgroundColor: '#efbb70'
	},
	{
		backgroundColor: '#acda53'
	},
	{
		backgroundColor: '#849be1'
	},
	{
		backgroundColor: '#94cced'
	}
]

const DialogDetail = ({ data, index }) => {
	const props = { index }
	const {t} = useTranslation()
	const classes = useStyles(props);
	const [mapDataDialog, setMapDataDialog] = useState({
		prizeList: [],
		singlePrizes: []
	})

	useEffect(()=> {
		const mData = mapDataResult(data)
		setMapDataDialog(mData)
	}, [data])

	const mapDataResult = function (data) {
		const periodNext = get(data, 'draw_no', 'N/A')
		const periodDate = get(data, 'date', 'N/A')
		const dayOfWeek = get(data, 'day_of_week', 'N/A')
		const prizeListData = get(data, 'prizeList', [])
		const singlePrizeListData = get(data, 'singlePrizeList', [])
		const singlePrizes = map(singlePrizeListData, (item, index) => {
			return {
				...item,
				index: index + 1,
				numbers: stringToArray(item.result)
			}
		})

		const prizeList = map(prizeListData, (item, index) => {
			return {
				...item,
				id: index,
				resuls: stringToArray(item.result)
			}
		})

		return {
			periodDate,
			dayOfWeek,
			period: periodNext,
			singlePrizes,
			prizeList
		}
	}

	const renderContent = () => {
		const {singlePrizes, prizeList} = mapDataDialog
		return (
			<div className={classes.winners__table__wrapper}>
				<div className={classes.winners__table}>
					{map(singlePrizes, (item, index)=>{
						return <div key={index} className={classes.group__result}>
							<div className={classes.title_name} style={colorBackgroundLevel[index]}>
								<span className={classes.bold__content}>
									{item.name}
								</span>
							</div>
							<div className={classes.group__result_m}>
								{map(item.result, (r, ii)=><span key={ii}>{r}</span>)}
							</div>
						</div>
					})}

					{map(prizeList, (item, index)=>{
						return <div key={index} className={classes.group__result}>
							<div className={classes.title_name} style={colorBackgroundLevel[3]}>
								<span className={classes.bold__content}>
									{item.name}
								</span>
							</div>
							<div className={classes.group__result_m}>
								{map(item.result, (r, ii)=><span key={ii}>{r}</span>)}
							</div>
						</div>
					})}
				</div>
			</div>
		)
	};

	const renderTitle = () => {
		const drawTitle = `${t('period')}:${mapDataDialog.period}`
		return (
			<div className={classes.winner_title}>
				<pre>{mapDataDialog.dayOfWeek}</pre>
				<div className={classes.title__group}>
					<span>
						{mapDataDialog.periodDate}
					</span>
					<span>
						<b>{drawTitle}</b>
					</span>
				</div>
			</div>
		)
	}

	return <div className={classes.winners}>
		{renderTitle()}
		{renderContent()}
	</div>
};

export default DialogDetail;
